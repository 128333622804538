.modal {
  @apply z-50 fixed pointer-events-auto;
  visibility: hidden;

  .modal-overlay {
    @apply fixed bg-dark inset-0 w-screen h-screen;
    z-index: -1;
  }

  .modal-content {
    @apply flex flex-col absolute w-full;
    @apply bg-white rounded-2xl text-left shadow-xl sm:my-8 sm:align-middle;
    left: 50%;
    transform: translateX(-50%);
  }

  &[data-modal-closable-value="true"] {
    .modal-content {
      @apply pt-4;
    }
  }

  .close-btn {
    @apply absolute top-0 right-0;
  }

  &.open {
    visibility: visible;

    .modal-overlay {
      animation-name: fadeInOverlay;
      animation-duration: 300ms;
      animation-timing-function: ease-in;
      animation-iteration-count: 1;
      animation-fill-mode: forwards;
    }

    .modal-content {
      animation-name: moveInModalContent;
      animation-duration: 300ms;
      animation-timing-function: ease-in-out;
      animation-iteration-count: 1;
      animation-fill-mode: forwards;
    }
  }

  &.hide {
    visibility: visible;
    animation-name: hideModal;
    animation-duration: 1ms;
    animation-delay: 300ms;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;

    .modal-overlay {
      animation-name: fadeOutOverlay;
      animation-duration: 300ms;
      animation-timing-function: ease-in;
      animation-iteration-count: 1;
      animation-fill-mode: forwards;
    }

    .modal-content {
      animation-name: moveOutModalContent;
      animation-duration: 300ms;
      animation-timing-function: ease-in-out;
      animation-iteration-count: 1;
      animation-fill-mode: forwards;
    }
  }

  /*** sizes ***/

  &.modal--md {
    .modal-content {
      @apply sm:max-w-lg;
    }
  }

  &.modal--lg {
    .modal-content {
      @apply sm:max-w-3xl;
    }
  }
}

@keyframes hideModal {
  from {
    visibility: visible;
  }
  to {
    visibility: hidden;
  }
}

@keyframes fadeInOverlay {
  from {
    opacity: 0;
  }
  to {
    opacity: 0.6;
  }
}

@keyframes fadeOutOverlay {
  from {
    opacity: 0.6;
  }
  to {
    opacity: 0;
  }
}

@keyframes moveInModalContent {
  from {
    opacity: 0;
    top: 25%;
  }
  to {
    opacity: 1;
    top: 10%;
  }
}

@keyframes moveOutModalContent {
  from {
    opacity: 1;
    top: 10%;
  }
  to {
    opacity: 0;
    top: 25%;
  }
}
