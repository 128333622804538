.proprietaire-candidatures {
  #main-container {
    max-width: 100%;
    overflow: hidden;
  }

  #frames-container {
    width: 200%;
  }

  #primary-translating-frame {
    &.swipe-out {
      animation: 600ms swipe-out forwards;
    }
    &.swipe-back-in {
      animation: 600ms swipe-back-in forwards;
    }
  }

  #secondary-translating-frame {
    &.swipe-in {
      animation: 600ms swipe-in forwards;
    }
    &.swipe-back-out {
      animation: 600ms swipe-back-out forwards;
    }
  }

  .ticket-bloc {
    @apply max-h-[9999px] mt-2 p-4 h-auto transition-all duration-500 overflow-hidden opacity-100;
    &:not(.open) {
      @apply opacity-0 max-h-0 p-0 mt-0;
    }
  }
}

@keyframes swipe-out {
  from {
    transform: translateX(0%);
  }
  to {
    transform: translateX(-100%);
  }
}

@keyframes swipe-in {
  from {
    transform: translateX(0%);
  }
  to {
    transform: translateX(-100%);
  }
}

@keyframes swipe-back-in {
  from {
    transform: translateX(-100%);
  }
  to {
    transform: translateX(0%);
  }
}

@keyframes swipe-back-out {
  from {
    transform: translateX(0%);
  }
  to {
    transform: translateX(100%);
  }
}
