.partenaire-landlords {
  .landlord-list {
    @apply flex-col w-full rounded-lg border border-grey my-6 divide-y divide-grey;
    li {
      @apply flex items-center text-oqoro-blue px-6 py-4;
      .landlord-list__icon_name_wrapper {
        @apply flex items-center space-x-2;
      }
      .landlord-list__name {
        @apply font-bold text-md;
      }
      .landlord-list__label {
        @apply hidden md:block font-extrabold text-lg w-1/4;
      }
      svg {
        @apply w-6 text-oqoro-purple ml-auto;
      }
    }
  }
}
