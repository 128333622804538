.page-gestion {
  h5 {
    @apply text-blue-main text-xl font-extrabold mb-4 relative;
    &:before {
      content: "•";
      @apply transform border-2 border-primary rounded-full text-3xl text-primary absolute text-center background-oqoro-layout;
      line-height: 16px;
      left: -42px;
      width: 20px;
      height: 20px;
    }
  }

  .page-gestion-article {
    @apply flex flex-col;
    a {
      @apply text-primary;
    }

    ul {
      @apply list-disc pl-8;
    }

    ol {
      @apply list-decimal pl-8;
    }

    img {
      @apply rounded-xl;
    }

    p {
      @apply text-light my-4;
    }

    h2 {
      @apply text-2xl font-medium my-8;
    }

    h3 {
      @apply text-lg text-primary font-medium my-6;
    }

    h4 {
      @apply font-medium;
    }
  }
}
