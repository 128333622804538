.partenaire-properties {
  .field.radio_buttons {
    @apply px-0;
    &.radio-inline {
      label {
        @apply text-oqoro-blue text-md font-medium mb-0 #{!important};
      }
      .control {
        @apply flex flex-col sm:flex-row;
        .radio {
          margin-right: 2rem;
        }
      }
    }
  }
}
