.page-zones-cities {
  @apply flex flex-col;
  a {
    @apply text-primary;
  }

  ul {
    @apply list-disc pl-8;
  }

  ol {
    @apply list-decimal pl-8;
  }

  img {
    @apply rounded-xl;
  }

  p {
    @apply text-grey font-medium text-lg my-2;
  }

  h1 {
    @apply text-2xl text-oqoro-blue font-extrabold my-6;
  }

  h2 {
    @apply text-xl font-extrabold my-6;
  }

  h3 {
    @apply text-lg text-primary font-extrabold my-4;
  }

  h4 {
    @apply font-extrabold;
  }
}
