@use "sass:map";

$base-color: black;
$white-color: white;
$light-color: darken(#ebf1f1, 10%);
$primary-color: #221A5B;
$success-color: #b3de43;
$warning-color: #fed500;
$danger-color: #f2866a;
$info-color: #004faa;
$flamingo-color: #7a62fe;

$theme-colors: (
        whity: (
                base: white,
                lighter: lighten(white, 5%),
                light: lighten(white, 5%),
                dark: darken(white, 5%),
                darker: darken(white, 10%),
                inverted: darken(white, 40%),
                text: white,
                text-dark: white,
        ),
        light: (
                base: $light-color,
                lighter: lighten($light-color, 5%),
                light: lighten($light-color, 5%),
                dark: darken($light-color, 5%),
                darker: darken($light-color, 10%),
                inverted: darken($light-color, 40%),
                text: darken($light-color, 20%),
                text-dark: darken($light-color, 30%),
        ),
        info: (
                base: $info-color,
                lighter: lighten($info-color, 40%),
                light: lighten($info-color, 40%),
                dark: darken($info-color, 5%),
                darker: darken($info-color, 10%),
                inverted: white,
                text: $info-color,
                text-dark: darken($info-color, 5%),
        ),
        primary: (
                base: $primary-color,
                lighter: lighten($primary-color, 5%),
                light: lighten($primary-color, 5%),
                dark: darken($primary-color, 5%),
                darker: darken($primary-color, 10%),
                inverted: white,
                text: $primary-color,
                text-dark: darken($primary-color, 5%),
        ),
        success: (
                base: $success-color,
                lighter: lighten($success-color, 15%),
                light: lighten($success-color, 5%),
                dark: darken($success-color, 10%),
                darker: darken($success-color, 15%),
                inverted: inherit,
                text: darken($success-color, 10%),
                text-dark: darken($success-color, 30%),
        ),
        warning: (
                base: $warning-color,
                lighter: lighten($warning-color, 5%),
                light: lighten($warning-color, 5%),
                dark: darken($warning-color, 3%),
                darker: darken($warning-color, 10%),
                inverted: darken($warning-color, 20%),
                text: darken($warning-color, 5%),
                text-dark: darken($warning-color, 20%),
        ),
        danger: (
                base: $danger-color,
                lighter: lighten($danger-color, 20%),
                light: lighten($danger-color, 20%),
                dark: darken($danger-color, 10%),
                darker: darken($danger-color, 15%),
                inverted: white,
                text: $danger-color,
                text-dark: darken($danger-color, 25%),
        ),
        flamingo: (
                base: $flamingo-color,
                lighter: lighten($flamingo-color, 20%),
                light: lighten($flamingo-color, 20%),
                dark: darken($flamingo-color, 10%),
                darker: darken($flamingo-color, 15%),
                inverted: white,
                text: $flamingo-color,
                text-dark: darken($flamingo-color, 25%),
        ),
        success_oqoro: (
                text: #D9F2E3,
                text-dark: #42BC73,
                light: #D9F2E3,
        )
);

// Heading

$heading-color-1: #004faa;

@function color($base, $variant: base) {
  $color: map.get($theme-colors, $base, $variant);
  @return $color;
}

// Buttons
$mba-colors: (
        primary: #0276fe,
        coal: #354960,
        slate: #929bb4,
        dynamic: #ffd500,
        forest: #37818d,
        sapphire: #0e0b73,
        neon: #49beff,
        wood: #a68c88,
        berry: #ce3888,
        default: #fff,
        success: #42bc73,
        warning: #ff9f1a,
        danger: #f2866a,
        lilas: #696ff8,
        discreet: #e4e4e4,
        white: #ffffff,
        black: #000000,
        background_lilas: #f2f6fd,
        background_grey: #ebf1f1,
        neon_shade: #edf8ff,
        forest_shade: #ebf2f4,
        wood_shade: #f6f4f3,
        berry_shade: #faebf3,
        sapphire_shade: #e7e7f1,
        border_shade: #e4e4e4,
);

@function mba_color($base) {
  @return map.get($mba-colors, $base);
}

// Oqoro colors

// Colors
$color-blue: #221A5B;
$color-saumon: #F76863;
$color-purple: #5B30F3;
$color-white: #FFFFFF;
$color-black: #000000;
$color-grey: #555555;

$oqoro-colors: (
  // Texts
        text-base: #4D5461,
        text-heading: $color-blue,
        // Border
        border: #D0D5DD,
        // Other
        saumon: $color-saumon,
        blue: $color-blue,
        purple: $color-purple,
        primary: $color-blue,
        coal: #394150, // gray-800
        slate: #6C727F, // gray-600
        dynamic: $color-saumon,
        forest: $color-blue,
        sapphire: $color-blue,
        neon: $color-blue,
        wood: #F4F4F6, // grey-200
        berry: $color-saumon,
        default: #fff,
        success: #12CB75,
        warning: $color-saumon,
        danger: $color-saumon,
        lilas: $color-purple,
        discreet: #F7F7FD, // purple-100
        white: #ffffff,
        black: #000000,
        background_lilas: #F0EFFB, // purple-200
        background_grey: #F4F4F6, // grey-200
        neon_shade: #F0EFFB, // purple-200
        forest_shade: #F0EFFB, // purple-200
        wood_shade: #F0EFFB, // purple-200
        berry_shade: #F0EFFB, // purple-200
        sapphire_shade: #F0EFFB, // purple-200
        border_shade: #F7F7FD, // purple-100
);

@function colors($color) {
  @return map.get($oqoro-colors, $color);
}

$colors: ["gray", "brand", "error", "danger", "warning", "success", "pink", "orange", "indigo", "primary", "blue"];
