@use "sass:map";

.cms {
  .article {
    .meta {
      h1 {
        @apply font-extrabold;
      }
      @apply text-sm pb-4;
      .date {
        @apply rounded-full bg-white px-3 py-2 inline-block text-oqoro-purple border border-grey;
      }
    }
    p {
    }
    h1 {
      @apply pb-4 font-extrabold;
    }

    h2 {
      @apply pb-4 font-extrabold text-2xl text-oqoro-blue;
    }

    h3 {
      @apply pb-4 font-extrabold text-xl text-oqoro-blue;
    }

    h4 {
      @apply pb-4 font-extrabold text-lg text-oqoro-blue;
    }

    .content {
      @apply py-4;
      img {
        @apply rounded-xl my-4;
      }
      a {
        @apply text-primary-dark underline;
      }
    }
    table {
      @apply table-auto py-4;
    }
    thead {
      @apply text-base font-semibold;
    }
    td,
    th {
      @apply border p-4 text-gray-600;
    }
    blockquote {
      @apply py-8  px-4 font-semibold italic;
    }
    ul {
      @apply list-disc pl-8;
    }
    li {
    }
  }
  .category-link {
    @apply rounded-full bg-light text-oqoro-purple px-3 py-2 inline-block border border-grey-light;
  }
  .category-link--xs {
    @apply px-2 py-1 text-sm;
  }

  .data_1_color {
    color: #49beff;
    background-color: #edf8ff;
  }
  .data_2_color {
    color: #37818d;
    background-color: #ebf2f4;
  }
  .data_3_color {
    color: #a68c88;
    background-color: #f6f4f3;
  }
  .data_4_color {
    color: #ce3888;
    background-color: #faebf3;
  }
  .data_5_color {
    color: #0e0b73;
    background-color: #e7e7f1;
  }
}
