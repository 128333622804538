.skeleton {
  .bar {
    @apply shimmer h-4 rounded-md w-full;
    &.thick {
      @apply h-6;
    }
    &.long {
      @apply w-4/5;
    }
    &.short {
      @apply w-1/5;
    }
  }

  .bloc {
    @apply shimmer h-24 rounded-md w-full;
  }

  .avatar {
    @apply shimmer rounded-full w-8 h-8;
  }
}


@layer utilities {
  .shimmer {
    @apply relative overflow-hidden bg-gray-200;
    &::after {
      @apply absolute inset-0;
      transform: translateX(-100%);
      background-image: linear-gradient(
                      90deg,
                      rgba(#fff, 0) 0,
                      rgba(#fff, 0.4) 20%,
                      rgba(#fff, 0.8) 60%,
                      rgba(#fff, 0)
      );
      animation: shimmer_anim 5s infinite;
      content: '';
    }
  }
}

@keyframes shimmer_anim {
  100% {
    transform: translateX(100%);
  }
}