#zenbar {
  background-image: none!important;
  background: transparent!important;

  .zenbar-container {
    @apply flex flex-col gap-6;
    .admin-link {
      @apply inline-flex items-center font-semibold text-base relative;
      &:hover:after {
        @apply ml-2 text-brand stroke-2 h-4 w-4 inline-block absolute -right-5;
        content: url('assets/images/app/icons/external-link.svg');
      }
    }
  }
}
