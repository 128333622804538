/*** tables ***/

table.oq-table-simple {
  @apply border border-gray-300 rounded-md min-w-full divide-y divide-gray-300 overflow-hidden;
  border-collapse: separate;
  border-spacing: 0;

  thead {
    tr {
      @apply h-10 divide-x divide-gray-300 bg-gray-100;
      th {
        @apply text-xs font-medium text-gray-600 px-4 h-10 text-left border-b border-gray-300;
      }
    }
  }

  tfoot {
    tr {
      @apply h-10 divide-x divide-gray-300 bg-gray-100 text-left;
      th {
        @apply text-sm font-semibold text-gray-600 px-4 h-10 border-t border-gray-300;
      }
    }
  }

  tbody {
    tr {
      @apply divide-x divide-gray-300 text-gray-600;
      &:not(:last-child) {
        td {
          @apply border-b border-gray-300;
        }
      }

      td {
        @apply p-4 text-sm;
      }
    }
  }
}

table.oq-table-simple-horizontal {
  @apply border border-gray-300 rounded-md min-w-full divide-y divide-gray-300 overflow-hidden;
  border-collapse: separate;
  border-spacing: 0;

  tr {
    @apply divide-x divide-gray-300 text-gray-900;
    &:nth-child(odd) {
      @apply bg-gray-100;
    }

    th, td {
      @apply text-sm text-left;
    }

    &:not(:last-child) {
      th, td {
        @apply border-b border-gray-300;
      }
    }

    th {
      @apply w-56 p-4 text-gray-600 font-normal;
    }

    td {
      @apply py-4 px-6 font-medium;
    }
  }
}

table.oq-table-simple,
table.oq-table-simple-horizontal {
  tbody {
    tr {
      td {
        & > a {
          @apply py-1 px-2 rounded-md leading-7 bg-gray-50 hover:bg-brand-50 hover:text-brand;
        }
      }
    }
  }
}
