.mba-file-upload {
  input[type="file"] {
    @apply hidden;
  }
  .file-list {
    @apply mb-4;
    li {
      @apply relative flex items-center text-xs rounded border py-2 px-4 mb-1 text-primary;
      button {
        @apply ml-2 text-light hover:bg-extra-light hover:text-danger;
      }

      .loader-icon {
        @apply hidden;
      }

      .check-icon {
        @apply hidden;
      }

      .delete-btn {
        @apply hidden;
      }

      .progress-bar {
        @apply absolute h-full left-0 bg-light animate-pulse;
        z-index: -1;
        transition: width 200ms;
      }

      &.upload-progress {
        @apply opacity-80 text-light;
        .loader-icon {
          @apply inline;
        }
      }

      &.upload-end {
        .progress-bar {
          @apply hidden;
        }
        .check-icon {
          @apply inline;
        }
        .delete-btn {
          @apply block;
        }
      }
    }
  }
}